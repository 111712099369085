import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import the dark and light logos
import logoDark from './images/bttr_logo_dark.jpg';
import logoLight from './images/bttr_logo_light.jpg';

const TestPage = () => {
  // Define an array with different default values for each row
  const defaultFormDataList = [
    { stockSymbol: 'MRVL', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'AZN', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'TSM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'LWAY', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'SMG', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'INGR', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'MMM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'MO', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'XOM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },
    { stockSymbol: 'V', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true },

    { stockSymbol: 'PLTR', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'JPM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'GS', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'CVX', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'IBM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'VZ', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'PEP', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'CVS', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'DUK', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
    { stockSymbol: 'WM', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: false },
  ];

  const [formData, setFormData] = useState(defaultFormDataList);

  const [selectedDate, setSelectedDate] = useState('2024-11-04');
  const [globalStartingBankValue, setGlobalStartingBankValue] = useState('1000');

  const [response, setResponse] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  // State to track dark mode status
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Check for dark mode and set up an event listener
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);

    const darkModeListener = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', darkModeListener);

    return () => darkModeMediaQuery.removeEventListener('change', darkModeListener);
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (index) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index].include = !newData[index].include;
      return newData;
    });
  };

  const handleGlobalChange = (e) => {
    const { name, value } = e.target;
    if (name === 'selectedDate') {
      setSelectedDate(value);
    } else if (name === 'startingBankValue') {
      setGlobalStartingBankValue(value);
    }
  };

  const addRow = () => {
    const newFormData = { stockSymbol: 'AAPL', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true };
    setFormData([...formData, newFormData]);
  };

  const removeRow = (index) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponse(null); // Clear previous response

    try {
      // Determine the base URL for the API
      const isLocal = window.location.hostname === 'localhost';
      const apiBaseUrl = isLocal ? 'http://localhost:4000' : '';

      // Filter included stocks and map them to the required structure
      const filteredFormData = formData.filter(data => data.include);
      const testRequestDataSet = filteredFormData.map((data) => ({
        stockSymbol: data.stockSymbol,
        percentSell: parseFloat(data.percentSell),
        percentPanicSell: parseFloat(data.percentPanicSell),
        percentExtremeHold: parseFloat(data.percentExtremeHold),
        algoType: data.algoType || "default" // Set algoType, use a default value if not provided
      }));
  
      // Create the main payload object that matches TestRequest
      const testRequestPayload = {
        startDateToTest: new Date(selectedDate).toISOString(), // ISO string for date
        endDateToTest: new Date(selectedDate).toISOString(),
        startingBankValue: parseFloat(globalStartingBankValue),
        testRequestDataSet: testRequestDataSet
      };
  
      // Submit the single payload object, not an array
      const response = await axios.post(
        `${apiBaseUrl}/api/test`,
        testRequestPayload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResponse(response.data);
    }catch (error) {
      console.error('Error starting test:', error);
      alert(error.message || 'Failed to start test. Please check the backend connection.');
    } finally {
      setIsLoading(false);
    }
  };

  const calculateProfitLossPercent = (startingBalance, potentialBalance) => {
    return ((potentialBalance - startingBalance) / startingBalance) * 100;
  };

  return (
    <Container className="p-4">
      {/* Logo Display based on Dark Mode */}
      <div className="text-center mb-4">
        <img
          src={isDarkMode ? logoDark : logoLight}
          alt="BTTR Logo"
          style={{ width: '150px', height: 'auto' }}
        />
        <div>
          <a href="http://test.invest.bttrnow.com:3000" target="_blank" rel="noopener noreferrer" className="grafana-link">
            Open Grafana Dashboard
          </a>
        </div>
      </div>

      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="text-center mb-4">BTTR Investing (Date Testing)</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Row className="align-items-center">
              <Col xs={3}>
                <Form.Label>Bank Value</Form.Label>
                <Form.Control
                  type="number"
                  name="startingBankValue"
                  value={globalStartingBankValue}
                  onChange={handleGlobalChange}
                  required
                />
              </Col>
              <Col xs={3}>
                  <Form.Label>Date to Test</Form.Label>
                  <Form.Control
                    type="date"
                    name="selectedDate"
                    value={selectedDate}
                    onChange={handleGlobalChange}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            {formData.map((data, index) => (
              <Form.Group className="mb-3" key={index}>
                <Row className="align-items-center mx-0">
                  <Col className="px-1">
                    <Form.Check 
                      type="checkbox"
                      label="Include"
                      checked={data.include}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </Col>
                  <Col className="px-3" style={{ flex: 1 }}>
                    <Form.Label>Stock Symbol</Form.Label>
                    <Form.Control
                      type="text"
                      name="stockSymbol"
                      value={data.stockSymbol}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </Col>
                  <Col className="px-3" style={{ flex: 1 }}>
                    <Form.Label>Sell</Form.Label>
                    <Form.Control
                      type="number"
                      name="percentSell"
                      value={data.percentSell}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </Col>
                  <Col className="px-3" style={{ flex: 1 }}>
                    <Form.Label>Panic</Form.Label>
                    <Form.Control
                      type="number"
                      name="percentPanicSell"
                      value={data.percentPanicSell}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </Col>
                  <Col className="px-3" style={{ flex: 1 }}>
                    <Form.Label>Extreme</Form.Label>
                    <Form.Control
                      type="number"
                      name="percentExtremeHold"
                      value={data.percentExtremeHold}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </Col>
                  <Col className="d-flex align-items-end px-1" style={{ flex: 1 }}>
                    <Button variant="danger" size="sm" onClick={() => removeRow(index)}>-</Button>
                  </Col>
                </Row>
              </Form.Group>
            ))}
            <Button variant="primary" onClick={addRow}>Add Row</Button>
            <Button variant="primary" type="submit" disabled={isLoading} className="ms-2">
              {isLoading ? 'Loading…' : 'Submit'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {response && response.data && response.data.length > 0 && (
      <>
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>Total Results</Card.Title>
            <Row>
              <Col>
                <strong>Total Revenue:</strong> {parseFloat(response.comboTotalRevenue).toFixed(2)}
              </Col>
              <Col>
                <strong>Total Made:</strong> {parseFloat(response.comboTotalMade).toFixed(2)}
              </Col>
              <Col>
                <strong>Total Lost:</strong> {parseFloat(response.comboTotalLost).toFixed(2)}
              </Col>
              <Col>
                <strong>% Change:</strong> {parseFloat(response.comboDailyPercentChange).toFixed(2)}
              </Col>
              <Col>
                <strong>Total Max Shares:</strong> {parseFloat(response.comboMaxSharesBought).toFixed(2)}
              </Col>
            </Row>
            
          </Card.Body>
        </Card>
        <Card className="mt-4">
        <Card.Body>
        <Card.Title>Individual Results</Card.Title>
          {response.data.map((testResult, index) => (
                <div key={index}>
                  <Row>
                    <Col>
                      <strong>Symbol:</strong> {testResult.symbol}
                    </Col>
                    <Col>
                      <strong>Total Revenue:</strong> {parseFloat(testResult.totalRevenue).toFixed(2)}
                    </Col>
                    <Col>
                      <strong>Total Made:</strong> {parseFloat(testResult.totalMade).toFixed(2)}
                    </Col>
                    <Col>
                      <strong>Total Lost:</strong> {parseFloat(testResult.totalLost).toFixed(2)}
                    </Col>
                    <Col>
                      <strong>% Change:</strong> {parseFloat(testResult.dailyPercentChange).toFixed(2)}
                    </Col>
                    <Col>
                      <strong>Max Shares:</strong> {parseFloat(testResult.maxSharesBought).toFixed(2)}
                    </Col>
                  </Row>
                </div>
              ))}
              </Card.Body>
        </Card>

        {/* New Section for Raw JSON Output */}
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>Raw Results</Card.Title>
            <pre style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {JSON.stringify(response, null, 2)}
            </pre>
          </Card.Body>
        </Card>
      </>
    )}
    </Container>
  );
};

export default TestPage;
