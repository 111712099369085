import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import the dark and light logos
import logoDark from './images/bttr_logo_dark.jpg';
import logoLight from './images/bttr_logo_light.jpg';

const TradePage = () => {
  const [formData, setFormData] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [response, setResponse] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  // State to track dark mode status
  const [isDarkMode, setIsDarkMode] = useState(false);

  // State to track button state
  const [buttonState, setButtonState] = useState('Enable');

  // State to track authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Define apiBaseUrl
  const isLocal = window.location.hostname === 'localhost';
  const apiBaseUrl = isLocal ? 'http://localhost:4000' : '';

  // Define getInvestDomain function
  function getInvestDomain() {
    const env = process.env.REACT_APP_BTTR_APP_ENV;
    switch (env) {
      case 'test':
        return 'test';
      case 'stage':
        return 'stage';
      case 'sandbox':
        return 'sandbox';
      case 'prod':
        return '';
      default:
        return 'unknown';
    }
  }

  const env = process.env.REACT_APP_BTTR_APP_ENV;
  const titleMap = {
    test: 'BTTR Investing (TEST)',
    stage: 'BTTR Investing (STAGE)',
    sandbox: 'BTTR Investing (SANDBOX)',
    prod: 'BTTR Investing (LIVE)',
  };

  // Memoize colorMap to prevent re-renders
  const colorMap = useMemo(() => ({
    sandbox: '#f5deb3', // faded tan
    prod: '#f08080',   // faded red
  }), []);

  const appTitle = titleMap[env] || 'BTTR Investing';

  useEffect(() => {
    document.title = appTitle;
    document.body.style.backgroundColor = colorMap[env] || 'white';
  }, [appTitle, env, colorMap]);

  useEffect(() => {
    const fetchInitialRowState = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/get-row-state`, { withCredentials: true });
        // Ensure response.data.rowData is an array and includes the 'include' property
        const rowData = Array.isArray(response.data.rowData) ? response.data.rowData : [];
        setFormData(rowData.map(row => ({
          ...row,
          include: row.include !== undefined ? row.include : false // Default to false if undefined
        })));
      } catch (error) {
        console.error('Error fetching initial row state:', error);
      }
    };

    fetchInitialRowState();
  }, [apiBaseUrl]);

  // Check for dark mode and set up an event listener
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);

    const darkModeListener = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', darkModeListener);

    return () => darkModeMediaQuery.removeEventListener('change', darkModeListener);
  }, []);

  useEffect(() => {
    const fetchButtonState = async () => {
      if (getInvestDomain() !== 'test') {
        try {
          const response = await axios.get(`${apiBaseUrl}/api/button-state`, { withCredentials: true });
          setButtonState(response.data.state);
        } catch (error) {
          console.error('Error fetching button state:', error);
        }
      }
    };
    fetchButtonState();
  }, [apiBaseUrl]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for the prompt to show in some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
    setUnsavedChanges(true); // Mark as unsaved changes
  };

  const handleCheckboxChange = (index) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index].include = !newData[index].include;
      return newData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponse(null); // Clear previous response

    try {
      const filteredFormData = formData.filter(data => data.include);
      const testRequestDataSet = filteredFormData.map((data) => ({
        stockSymbol: data.stockSymbol,
        percentSell: parseFloat(data.percentSell),
        percentPanicSell: parseFloat(data.percentPanicSell),
        percentExtremeHold: parseFloat(data.percentExtremeHold),
        algoType: data.algoType || "default"
      }));

      const testRequestPayload = {
        testRequestDataSet: testRequestDataSet
      };

      const response = await axios.post(
        `${apiBaseUrl}/api/test`,
        testRequestPayload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResponse(response.data);
    } catch (error) {
      console.error('Error starting test:', error);
      alert(error.message || 'Failed to start test. Please check the backend connection.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async () => {
    const confirmMessage = buttonState === 'Enable' ? 'Are you sure you want to enable the service?' : 'Are you sure you want to disable the service?';
    if (window.confirm(confirmMessage)) {
      try {
        const newState = buttonState === 'Enable' ? 'Disable' : 'Enable';
  
        // Prepare the row data
        const filteredFormData = formData.filter(data => data.include);
        const rowData = filteredFormData.map((data) => ({
          stockSymbol: data.stockSymbol,
          percentSell: parseFloat(data.percentSell),
          percentPanicSell: parseFloat(data.percentPanicSell),
          percentExtremeHold: parseFloat(data.percentExtremeHold),
          algoType: data.algoType || "default",
          include: data.include
        }));
  
        // Always update the button state
        const response = await axios.post(`${apiBaseUrl}/api/button-state`, { state: newState }, { withCredentials: true });
        if (response.status === 200) {
          setButtonState(newState);
        }
  
        // Send the payload to /api/run if enabling the service
        if (newState === 'Disable') {
          await axios.post(`${apiBaseUrl}/api/run`, { rowData: rowData }, { withCredentials: true });
        } else if (newState === 'Enable') {
          await axios.post(`${apiBaseUrl}/api/stop`, { rowData: rowData }, { withCredentials: true });
          // Save row state
          await axios.post(`${apiBaseUrl}/api/set-row-state`, { rowData: rowData }, { withCredentials: true });
          console.log('Row states saved successfully.');
          setUnsavedChanges(false); // Reset unsaved changes flag
        }
      } catch (error) {
        console.error('Error updating button state:', error);
      }
    }
  };

  const addRow = () => {
    const newFormData = { stockSymbol: 'AAPL', percentSell: '0.02', percentPanicSell: '0.05', percentExtremeHold: '0.10', include: true };
    setFormData([...formData, newFormData]);
  };

  const removeRow = (index) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };

  const handleSaveClick = async () => {
    const confirmMessage = 'Are you sure you want to save the current row states?';
    if (window.confirm(confirmMessage)) {
      try {
        // Prepare the row data including the 'include' property
        const rowData = formData.map((data) => ({
          stockSymbol: data.stockSymbol,
          percentSell: parseFloat(data.percentSell),
          percentPanicSell: parseFloat(data.percentPanicSell),
          percentExtremeHold: parseFloat(data.percentExtremeHold),
          algoType: data.algoType || "default",
          include: data.include // Ensure 'include' is saved
        }));

        // Send the row data to /api/set-row-state
        await axios.post(`${apiBaseUrl}/api/set-row-state`, { rowData: rowData }, { withCredentials: true });
        console.log('Row states saved successfully.');
        setUnsavedChanges(false); // Reset unsaved changes flag
      } catch (error) {
        console.error('Error saving row states:', error);
      }
    }
  };

  return (
    <Container className="p-4">
      {isAuthenticated ? (
        <>
          <div className="text-center mb-4">
            <img
              src={isDarkMode ? logoDark : logoLight}
              alt="BTTR Logo"
              style={{ width: '150px', height: 'auto' }}
            />
            <div>
              <a href={`http://${getInvestDomain()}.invest.bttrnow.com:3000`} target="_blank" rel="noopener noreferrer" className="grafana-link">
                Open Grafana Dashboard
              </a>
            </div>
          </div>

          <Card className="shadow-sm">
            <Card.Body>
              <h2 className="text-center mb-4">{appTitle}</h2>
              <Form onSubmit={handleSubmit}>
                {formData.map((data, index) => (
                  <Form.Group className="mb-3" key={index}>
                    <Row className="align-items-center mx-0">
                      <Col className="px-1">
                        <Form.Check 
                          type="checkbox"
                          label="Include"
                          checked={data.include}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </Col>
                      <Col className="px-3" style={{ flex: 1 }}>
                        <Form.Label>Stock Symbol</Form.Label>
                        <Form.Control
                          type="text"
                          name="stockSymbol"
                          value={data.stockSymbol}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                      </Col>
                      <Col className="px-3" style={{ flex: 1 }}>
                        <Form.Label>Sell</Form.Label>
                        <Form.Control
                          type="number"
                          name="percentSell"
                          value={data.percentSell}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                      </Col>
                      <Col className="px-3" style={{ flex: 1 }}>
                        <Form.Label>Panic</Form.Label>
                        <Form.Control
                          type="number"
                          name="percentPanicSell"
                          value={data.percentPanicSell}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                      </Col>
                      <Col className="px-3" style={{ flex: 1 }}>
                        <Form.Label>Extreme</Form.Label>
                        <Form.Control
                          type="number"
                          name="percentExtremeHold"
                          value={data.percentExtremeHold}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                      </Col>
                      <Col className="d-flex align-items-end px-1" style={{ flex: 1 }}>
                        <Button variant="danger" size="sm" onClick={() => removeRow(index)}>-</Button>
                      </Col>
                    </Row>
                  </Form.Group>
                ))}
                <Button variant="primary" onClick={addRow}>Add Row</Button>
                <Button 
                  variant={buttonState === 'Enable' ? 'primary' : 'danger'} 
                  onClick={handleButtonClick} 
                  disabled={isLoading} 
                  className="ms-2"
                >
                  {isLoading ? 'Loading…' : buttonState}
                </Button>
                <Button variant="success" onClick={handleSaveClick} className="ms-2">
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
          {response && response.data && response.data.length > 0 && (
          <>
            <Card className="mt-4">
              <Card.Body>
                <Card.Title>Total Results</Card.Title>
                <Row>
                  <Col>
                    <strong>Total Revenue:</strong> {parseFloat(response.comboTotalRevenue).toFixed(2)}
                  </Col>
                  <Col>
                    <strong>Total Made:</strong> {parseFloat(response.comboTotalMade).toFixed(2)}
                  </Col>
                  <Col>
                    <strong>Total Lost:</strong> {parseFloat(response.comboTotalLost).toFixed(2)}
                  </Col>
                  <Col>
                    <strong>% Change:</strong> {parseFloat(response.comboDailyPercentChange).toFixed(2)}
                  </Col>
                  <Col>
                    <strong>Total Max Shares:</strong> {parseFloat(response.comboMaxSharesBought).toFixed(2)}
                  </Col>
                </Row>
                
              </Card.Body>
            </Card>
            <Card className="mt-4">
            <Card.Body>
            <Card.Title>Individual Results</Card.Title>
              {response.data.map((testResult, index) => (
                    <div key={index}>
                      <Row>
                        <Col>
                          <strong>Symbol:</strong> {testResult.symbol}
                        </Col>
                        <Col>
                          <strong>Total Revenue:</strong> {parseFloat(testResult.totalRevenue).toFixed(2)}
                        </Col>
                        <Col>
                          <strong>Total Made:</strong> {parseFloat(testResult.totalMade).toFixed(2)}
                        </Col>
                        <Col>
                          <strong>Total Lost:</strong> {parseFloat(testResult.totalLost).toFixed(2)}
                        </Col>
                        <Col>
                          <strong>% Change:</strong> {parseFloat(testResult.dailyPercentChange).toFixed(2)}
                        </Col>
                        <Col>
                          <strong>Max Shares:</strong> {parseFloat(testResult.maxSharesBought).toFixed(2)}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  </Card.Body>
            </Card>
          </>
        )}
        </>
      ) : (
        <LoginForm onLoginSuccess={() => setIsAuthenticated(true)} />
      )}
    </Container>
  );
};

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const isLocal = window.location.hostname === 'localhost';
  const apiBaseUrl = isLocal ? 'http://localhost:4000' : '';
  const env = process.env.REACT_APP_BTTR_APP_ENV;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); 
    try {
      if (env === 'test' && username === 'test' && password === 'test') {
        onLoginSuccess();
      } else {
        const response = await axios.post(`${apiBaseUrl}/api/authenticate`, { username, password }, { withCredentials: true });
        if (response.status === 200) {
          onLoginSuccess();
        } else {
          setError('Invalid username or password');
        }
      }
    } catch (err) {
      setError('Error connecting to server');
    } finally {
      setIsSubmitting(false); 
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: '400px' }} className="shadow-sm">
        <Card.Body>
          <Card.Title className="text-center mb-4">Login</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            {error && <div className="text-danger mb-3">{error}</div>}
            <Button 
              type="submit" 
              variant={isSubmitting ? "secondary" : "primary"} 
              className="w-100"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Logging in...' : 'Login'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TradePage;
