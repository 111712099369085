import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TestPage from './TestPage';
import TradePage from './TradePage';

const App = () => {
  const env = process.env.REACT_APP_BTTR_APP_ENV;
  const [page, setPage] = useState('test'); // Default to TestPage

  return (
    <div className="App">
      {env === 'test' && (
        <div className="navigation fixed-top bg-light p-2">
          {page === 'test' ? (
            <Button variant="outline-primary" onClick={() => setPage('trade')}>
              Trade Page
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={() => setPage('test')}>
              Test Page
            </Button>
          )}
        </div>
      )}
      <div className="content mt-5">
        {env === 'test' ? (page === 'test' ? <TestPage /> : <TradePage />) : <TradePage />}
      </div>
    </div>
  );
};

export default App;